.row {
    margin-right: -7px;
    margin-left: -7px;
}
.col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto {
    padding-right: 7px;
    padding-left: 7px;
}

.m-1,.mx-1,.ml-1{
  margin-left: $m1 !important;
}
.m-1,.mx-1,.mr-1{
  margin-right: $m1 !important;
}
.m-1,.my-1,.mt-1{
  margin-top: $m1 !important;
}
.m-1,.my-1,.mb-1{
  margin-bottom: $m1 !important;
}

.m-2,.mx-2,.ml-2{
  margin-left: $m2 !important;
}
.m-2,.mx-2,.mr-2{
  margin-right: $m2 !important;
}
.m-2,.my-2,.mt-2{
  margin-top: $m2 !important;
}
.m-2,.my-2,.mb-2{
  margin-bottom: $m2 !important;
}

.m-3,.mx-3,.ml-3{
  margin-left: $m3 !important;
}
.m-3,.mx-3,.mr-3{
  margin-right: $m3 !important;
}
.m-3,.my-3,.mt-3{
  margin-top: $m3 !important;
}
.m-3,.my-3,.mb-3{
  margin-bottom: $m3 !important;
}

.m-4,.mx-4,.ml-4{
  margin-left: $m4 !important;
}
.m-4,.mx-4,.mr-4{
  margin-right: $m4 !important;
}
.m-4,.my-4,.mt-4{
  margin-top: $m4 !important;
}
.m-4,.my-4,.mb-4{
  margin-bottom: $m4 !important;
}

.m-5,.mx-5,.ml-5{
  margin-left: $m5 !important;
}
.m-5,.mx-5,.mr-5{
  margin-right: $m5 !important;
}
.m-5,.my-5,.mt-5{
  margin-top: $m5 !important;
}
.m-5,.my-5,.mb-5{
  margin-bottom: $m5 !important;
}

.m-gap,.mx-gap,.ml-gap{
  margin-left: $gap !important;
}
.m-gap,.mx-gap,.mr-gap{
  margin-right: $gap !important;
}
.m-gap,.my-gap,.mt-gap{
  margin-top: $gap !important;
}
.m-gap,.my-gap,.mb-gap{
  margin-bottom: $gap !important;
}

.m-gap-sm,.mx-gap-sm,.ml-gap-sm{
  margin-left: $gap-sm !important;
}
.m-gap-sm,.mx-gap-sm,.mr-gap-sm{
  margin-right: $gap-sm !important;
}
.m-gap-sm,.my-gap-sm,.mt-gap-sm{
  margin-top: $gap-sm !important;
}
.m-gap-sm,.my-gap-sm,.mb-gap-sm{
  margin-bottom: $gap-sm !important;
}

.btn, .form-control{
  border-radius: 0;
}
.btn-primary{
  background-color: $color-brand;
  border-color: $color-brand;
  text-transform: uppercase;
  &:hover,&:active,&:focus,&:target{
    background-color: $color-brand-darker;
    border-color: $color-brand-darker;
  }
  &.disabled, &:disabled{
    background-color: $grey;
    border-color: $grey;
  }
}
.btn-white{
    color: $font-color;
    background-color: $white;
    border-color: $white;
    &:hover{
      background-color: $grey-light;
    border-color: $grey-light;
    }
}
.btn-white2{
    color: $color-brand;
    background-color: $white;
    border-color: $color-brand;
    &:hover{
      background-color: rgba($color: $color-brand, $alpha: 0.2);
    border-color: $color-brand;
    color: $color-brand-darker;
    }
}
.custom-control{
  font-size: 1rem;
  >label{
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: .2em;
    &:hover{
      background-color: rgba($color: #000000, $alpha: 0.1);
    }
  }
  .custom-radio {
  .custom-control-label::before {
    border-radius: 0;
  }

  .custom-control-input:checked ~ .custom-control-label {
    &::before {
      background-color: $color-brand;
    }
    &::after {
    }
  }

  .custom-control-input:disabled {
    &:checked ~ .custom-control-label::before {
      background-color: $grey-light;
    }
  }
}
}
table{
  &.table{
  margin-bottom: 0;
    .clear{
      >tbody{
        >tr{
          >td{
            padding: .3em .5em;
            border: none !important;
            &:first-child{
              padding-left: 0 !important;
            }
            &:last-child{
              padding-right: 0 !important;
            }
          }
        }
      }
    }
  }
}
.modal {
  .modal-dialog {
    max-width: unset;
    }
}


input[type="range"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  height: 0.65rem;
  margin: 0.65rem;
  border-radius: 25px;
  outline: none;
  cursor: pointer;
  background: $grey-light;
  position: relative;
}

input#customRange1:before {
  background-color: red;
  height: 0.65rem;
  width: 0.65rem;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10000000000;
  content: ""
}


/*Chrome thumb*/

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  -moz-appearance: none;
  height: 24px;
  width: 24px;
  border: 4px solid $white;
  border-radius: 50%;
  background-color: $color-brand;
  color: $color-brand;
  -webkit-box-shadow: 1px 1px 5px 1px rgba(0,0,0,0.19); 
  box-shadow: 1px 1px 5px 1px rgba(0,0,0,0.19);
}

/*Mozilla thumb*/

input[type="range"]::-moz-range-thumb {
  -webkit-appearance: none;
  -moz-appearance: none;
  height: 18px;
  width: 18px;
  border: 4px solid $white;
  border-radius: 50%;
  background-color: $color-brand;
  color: $color-brand;
  -webkit-box-shadow: 1px 1px 5px 1px rgba(0,0,0,0.19); 
  box-shadow: 1px 1px 5px 1px rgba(0,0,0,0.19);
}

// Mozilla
input[type="range"]::-moz-range-progress {
  background-color: $color-brand;
  height: inherit;
  border-radius: 25px;
}

// Chrome
input[type="range"]:before {
  position: absolute;
  background-color: $color-brand;
  height: 20px;
  border-radius: 25px;
}

// IE
input[type="range"]::-ms-fill-lower {
  background: $color-brand;
  border-radius: 25px;
  height: 0.65rem;
}
input[type="range"]::-ms-fill-upper {
  background: transparent;
  border-radius: 25px;
  height: 0.65rem;
}


/*IE & Edge input*/

// input[type=range]::-ms-track {
//   width: 300px;
//   height: 6px;
//   /*remove bg colour from the track, we'll use ms-fill-lower and ms-fill-upper instead */
//   background: transparent;
//   /*leave room for the larger thumb to overflow with a transparent border */
//   border-color: transparent;
//   border-width: 2px 0;
//   /*remove default tick marks*/
//   color: transparent;
// }

.pointer {
  cursor: pointer;
}

textarea:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus,
.uneditable-input:focus {   
  border-color: $color-brand;
  outline: 0 none;
}

.pagination{
  margin-top: $gap;
  .page-item{
    margin: 2px;
    >.page-link{
      color: $font-color;

      &:hover {
        background-color: $grey-light;
        color: $color-brand;
        border: 1px solid $color-brand;
        transition-duration: .25s;
      }
    }
    &.active{
      >.page-link{
        color: $white;
        background-color: $color-brand;
        border-color: $color-brand;
      }
    }
    &:last-child .page-link {
      border-radius: 0%;
    }
    &:first-child .page-link {
      border-radius: 0%;
    }
  }
}