#side-shopping-cart {
    font-size: 1rem;
    position: fixed;
    left: 100%;
    padding-right: $gap;
    z-index: 10;
    &.active {
        transform: translateX(-100%);
        transition: transform 0.5s ease 0s;
}
    &.inactive {
        transition: transform 0.5s ease 1s;
    }
    .side-cart-menu-wrapper {
        height: 100%;
        overflow-y: auto;
        background-color: $background-tile;
        padding: $gap $gap;
        border-top: 1px solid rgba(195, 195, 195, 0.2);
        flex-direction: column;
        justify-content: space-between;
        box-shadow: $box-shadow1-down;
        >.side-cart-footer {
            margin-top: $gap;
            .btn{
                text-transform: uppercase;
                width: 100%;
                font-size: 1.2rem;
                >svg{
                    transform: translateY(-0.2em);
                }
                >span{
                    font-size: 1em;
                }
            }
        }
    }
    .side-cart-position-list {
        margin-top: $gap;
        .side-cart-position-inner{
            display: flex;
            flex-direction: column;
            .side-cart-position-title{
                text-transform: uppercase;
                margin-bottom: .75rem;
            }
            .side-cart-position-main{
                display: flex;
                .side-cart-position-image{
                    padding-right: .5rem;
                    >img{
                        object-fit: contain;
                        max-width:80px;
                        max-height:40px;
                    }
                }
                .side-cart-position-desc{
                    display: flex;
                    flex-direction: column;
                    >span{
                        margin-bottom: .5rem;
                        margin-top: .1rem;
                        white-space: nowrap;
                    }
                    .side-cart-position-amount{
                        display: flex;
                        .side-cart-position-packaging-quantity{
                            color:$color-brand
                        }
                    }
                }
            }
        }
        >.side-shopping-cart-position-wrapper,>.side-cart-position-form-wrapper {
            display: flex;
            flex-flow: column;
            border: 1px solid $border-color;
            font-size: 1rem;
            margin-bottom: $gap-sm;
        }
        >.side-shopping-cart-position-wrapper {
            padding: .75rem 1rem;

            .side-cart-position-price{
                width: 8em;
                display: flex;
                align-items: flex-end;
                justify-content: flex-end;
                >span{
                    white-space: nowrap;
                }
            }
        }
        
        >.side-cart-position-form-wrapper {
            padding: .75rem .5rem;
            >.side-cart-position-form-header{
                display: flex;
                flex-direction: column;
                padding: 0 .5rem .75rem ;
                border-bottom: 1px solid $color-brand;
                >.side-cart-position-form-title{
                    text-transform: uppercase;
                    color: $color-brand;
                    line-height: 1.5;
                    //margin-bottom: $m1;
                }
                >.side-cart-position-form-header-desc{
                    font-size: .9em;
                }
                >.side-cart-position-form-price{
                    width: 8em;
                    display: flex;
                    align-items: flex-end;
                    justify-content: flex-end;
                }
            }
            .side-cart-position-inner{
                padding: .75rem .5rem ;
                border-bottom: 1px solid $border-color;
                &:last-child{
                    padding-bottom: 0;
                    border-bottom: none;
                }
            }
        }
    }
    .side-cart-sum-wrapper {
        text-align: end;
        font-size: 1.2em;
        margin-right: 1rem;
        margin-top: $gap-sm;
        .side-cart-sum-title {
            color: $color-brand;
            text-transform: uppercase;
            white-space: nowrap;
            margin-right: $gap-sm;
        }
        .side-cart-sum {
            color: $color-brand;
            font-size: 1.1em;
            white-space: nowrap;
        }
    }
}



//cart
.cart-menu[menu='true'] {
    animation: slide-in 0.5s forwards;
    -webkit-animation: slide-in 0.5s forwards;
}

.cart-menu[menu='false'] {
    animation: slide-out 0.5s forwards;
    -webkit-animation: slide-out 0.5s forwards;
}


@media only screen and (min-width: $bp-md) {
    .side-cart-menu-wrapper {
        display: flex;
    }
}
@media only screen and (max-width: $bp-md) {
    .side-cart-menu-wrapper {
        display: none;
    }
}
@media (max-width: 576px) {
    #side-shopping-cart{
        display: none;
    }
}